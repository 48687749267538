import React from "react";
import { useParams } from "react-router-dom";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import Loader from "./Loader";
// This is the query that Apollo Client will send to the WP site.
const GET_POST = gql`
  query GET_POST($id: ID!) {
    post(id: $id) {
      id
      postId
      title
      date
      content
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
  }
`;

function Single({ searchquery }) {
  const { id } = useParams();

  return (
    <Query query={GET_POST} variables={{ id }}>
      {({ loading, error, data }) => {
        return (
          <div className="wrapper">
            {loading && <Loader />}
            {data && (
              <div>
                <Link to="/" className="back">
                  Zurück
                </Link>
                <div
                  style={{
                    ...styles.card,
                    backgroundImage:
                      "url(" + data.post.featuredImage?.node?.sourceUrl + ")",
                  }}
                ></div>
                <div
                  style={{ padding: "2rem", color: "#FFF", background: "#000" }}
                >
                  <h1 style={{ fontSize: "1.5rem" }}>{data.post.title}</h1>
                  <div
                    dangerouslySetInnerHTML={{ __html: data.post.content }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        );
      }}
    </Query>
  );
}

export default Single;

const styles = {
  card: {
    height: "200px",
    backgroundSize: "cover",
    color: "#FFF",
    position: "relative",
    display: "block",
  },
  title: {
    position: "absolute",
    bottom: "1rem",
    left: "1rem",
    fontSize: "1.2rem",
  },
  date: {
    position: "absolute",
    top: "1rem",
    left: "1rem",
  },
};
