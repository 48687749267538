import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Post({ post }) {
  const [cardclass, setCardclass] = useState("card");
  useEffect(() => {
    setTimeout(function () {
      setCardclass("card visible");
    }, 300);
  }, []);
  return (
    <Link
      className={cardclass}
      to={"/post/" + post.id}
      style={{
        ...styles.card,
        backgroundImage: "url(" + post.featuredImage?.node?.sourceUrl + ")",
      }}
    >
      <h2 style={styles.title}>{post.title}</h2>
      <span style={styles.date}>
        {new Date(post.date).toLocaleDateString("de-DE")}
      </span>
    </Link>
  );
}

const styles = {
  card: {
    height: "200px",
    backgroundSize: "cover",
    color: "#FFF",
    position: "relative",
    display: "block",
  },
  title: {
    position: "absolute",
    bottom: "1rem",
    left: "1rem",
    right: "1rem",
    fontSize: "1.2rem",
    zIndex: 1,
  },
  date: {
    position: "absolute",
    top: "1rem",
    left: "1rem",
    zIndex: 1,
  },
};
