import React from "react";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import Posts from "./Posts";
import Single from "./Single";
import "./App.css";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import "./App.css";

function App() {
  const client = new ApolloClient({
    uri: "https://www.solingen-paladins.de/graphql"
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Switch>
          <Route path="/post/:id" component={Single} />
          <Route exact path="/" component={Posts} />
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
